/*@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@300;400&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600&display=swap');*/

/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');*/

/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


/*@font-face {*/
/*    !*font-family: 'Arcon';*!*/
/*    !*src:url('Arcon.ttf.woff') format('woff'),*!*/
/*    !*    url('Arcon.ttf.svg#Arcon') format('svg'),*!*/
/*    !*    url('Arcon.ttf.eot'),*!*/
/*    !*    url('Arcon.ttf.eot?#iefix') format('embedded-opentype'); *!*/
/*    !*font-weight: normal;*!*/
/*    !*font-style: normal;*!*/

/*    font-family: 'Montserrat';*/
/*    font-weight: normal;*/
/*    font-style: normal;*/

/*}*/
/*html {*/
/*    box-sizing: border-box;*/
/*}*/


.thin {
    font-weight:  100;
    font-family: 'Montserrat', sans-serif;
}

.regular {
    font-weight:  400;
    font-family: 'Montserrat', sans-serif;
}

.bold {
    font-weight:  700;
    font-family: 'Montserrat', sans-serif;
}

.black {
    font-weight:  900;
    font-family: 'Montserrat', sans-serif;
}

.bold td {
    font-weight: 600 !important;
}




body {
    border-radius: 30px;
    height: calc(100% - 4.5em);
    overflow: hidden;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;
    font-family: 'Montserrat';
    font-style: normal; 
    width: calc(100% - 1em);
    background-image: url('../imgs/background.png');
}
.h6 {
    font-size: 6em;
    font-weight: 400;
    font-variant: initial;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    font-style: normal;
    background-color: #000000;
    color: transparent;
    text-shadow: 2px 2px 3px #ffffff;
    -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;

}
.h7 {
    font-size: 6em;
    font-weight: 400;
    font-variant: initial;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    font-style: normal;
    background-color: #000000;
    color: transparent;
    text-shadow: 2px 2px 3px #ffffff;
    -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
}
.h8 {
    font-size: 1.5em;
    font-weight: 400;
    font-variant: initial;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    font-style: normal;
    background-color: #000000;
    color: transparent;
    text-shadow: 1px 1px 1px #ffffff;
    -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;

}

#Background {
    width: 100vw;
    height: 100vh;
    background-image: url('../imgs/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

.top_card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.middle_card,
.bottom_card,
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 40em;
    max-width: 90vw;
    height: 27.5em;
    background-color: #F5F5F5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(77,166,221, 0.6);
    backdrop-filter: blur(7.5px);
    border-radius: 40px;
}

form {
    height: auto;
    margin: 0 auto;
    border: 0;
}

input,
select {
    border: 1px solid #4DA6DD;
    border-radius: 22.5px;
    height: 2.5em;
    width: 100%;
    padding: 0 1em;
    /*transform: translate(-1em, 0);*/
}


select {
    width: 112.5%;
}

select option {
    background-color: #f7f7f9;
}
Select {
    border: 1px solid #4DA6DD;
    border-radius: 22.5px;
    height: 2.5em;
    width: 100%;
    padding: 0 1em;
    transform: translate(-1em, 0);
}


Select {
    width: 112.5%;
}

Select option {
    background-color: #f7f7f9;
}

input:focus {
    outline: none;
    border: 1px solid #b7cbf3;
}

input[type=checkbox] {
    /*transform: translate(6.5em, -.5em);*/
    width: 1em;
    height: 1em;
    accent-color: #4DA6DD;
    cursor: pointer;
}

input[type=submit] {
    background: #4DA6DD;
    border: 0px solid black;
    color: #f7f7f9;
    cursor: pointer;
    transform: translate(0, 0) !important;
}

input[type=search] {
    position: absolute;
    top: .5em;
    left: .5em;
    border-radius: 30px;
    width: 25em;
    max-width: 90%;
    margin: 0 auto !important;
    transform: translate(0, 0);
    background: transparent url("../imgs/icons/lente.svg") no-repeat 95% 50%;
    background-size: 22.5px;
    padding-right: 3.25em;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.pass_eye {
    position: absolute;
    /*top: .75em;*/
    /*right: -.5em;*/
    top: .15em;
    right: 1em;
    color: #000;
    cursor: pointer;
}

.top_card {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 2.5em;
}

.App-logo {
    height: auto;
    width: 20em;
}

.middle_card div {
    position: relative;
    height: 100%;
}

.middle_card label {
    position: absolute;
    /*top: -.5em;*/
    left: .75em;
    font-size: 80%;
    color: #f7f7f9;
}

.bottom_card {
    color: #f7f7f9;
    text-decoration: none;
    font-size: 80%;
}

.bottom_card a:visited {
    color: #f7f7f9;
    text-decoration: none;
}

.error {
    color: red;
    font-size: 80%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(1em, 0);
}

#Base { 
    height: 100%;
    left: 0;
    width: 100%;
}

#Menu {
    margin-top: 20px;
    width: 100%;
    height: auto;
    position: relative;
    justify-content: center;
    align-items: center;

}



.menuContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
}

.menuItem {
    position: relative;
    /*margin-left: 2%;*/
    margin-left: 1%;
    margin-right: 1%;
    /*width: 55px;    */
    /*height: 55px;*/
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #CDDEFF;
    text-decoration: none;
    border-radius: 100%;
    color: #CDDEFF;
    transition: .3s;
    font-size: 2px;
    border: 1px solid #CDDEFF;
}

.menuItem img {
    width: 30px;
    height: 30px;
}

.menuItem:hover {
    background: rgba(0,163,223, 0.1);
    width: 55px;    
    height: 55px;
    border-radius: 100%;
    border: 1px solid #CDDEFF;
    padding-top: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center ;
    

}

.menuItemActive {
    background: #00A3DF;
    width: 55px;    
    height: 55px;
    border-radius: 100%;
    padding-top: 1em;
    color: #ffffff;
}
.legendaItem {
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    cursor: default;
    left: 100%;
}
.legendaItemStandard, .legendaItemBorder, .legendaItemBorderAlarm, .legendaItemBed {
    border-radius: 5px 20px;
    width: 15px;
    padding: 13px;
    color: black;
    font-weight: 600;
    font-size: 1rem;
    justify-content: center;
    display: flex;
    height: 10px;
    align-items: center;
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
}
.legendaItemStandard {
    border: 4px solid lightgray;
    margin-right: 10px;
}
.legendaItemBorder {
    border: 4px solid #168AF5;
    margin-right: 10px;
}
.legendaItemBorderAlarm {
    border: 4px solid red;
    margin-right: 10px;
}
.legendaItemBed {
    border: 4px solid transparent;
    margin-right: 10px;
}

.legenda{
    width: 100%;
    height: 100%;
    position: relative;
    right: 2%;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
    font-size: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;
}

.legendaItem img {
    cursor: default;
}

.legendName {
    font-size: 13px;
    text-align:center;
    vertical-align: middle;
}


#Content {
    background: rgba(0, 0, 0, 0, 0.1);
    border-radius: 30px;
    height: calc(100% - 1em);
    margin-right: 0.5em;
    position: absolute;
    min-width: calc(100% - 10.5em);
    top: 0.5em;
    width: calc(100%);
}

#Header {
    position: relative;
    height: 3em;
    display: flex;
    flex-direction: row;
    left: 0;
    top: 0;
    width: calc(96%);
    justify-content: space-between;
    align-items: center;
    /*padding: 0 1em;*/
    border-radius: 30px 30px 0 0;
    font-style: normal;
    font-weight: 400;
    /*margin-right: 20px;*/
    /*margin-left: 20px;*/
    padding: 0.5em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}


@media screen and (max-width: 679px) {
    #Header {
        font-size: small;
    }

    #Strutture{
        font-size: x-small;
    }
}

@media screen and (max-width: 565px) {
    #Header {
        font-size: x-small;
    }
    .bell {
        width: 29px;
    }
    /*.selettorePurple {*/
    /*    font-size: small;*/
    /*}*/
    .moblie1 {
        display: none;
    }
}
.firstLettert {
    p::first-letter {
        font-size: 100%;
        display: block;
    }
}



.headerPopup {
    position: relative !important;
}

.headerPopup div {
    cursor: pointer;
}

.header-alig {
    /*display: flex;*/
    /*justify-content: space-between !important;*/
    /*align-items: center !important;*/
    margin-top: 5px;
}

#Body {
    border-radius: 30px;
    height: calc(100% - 12em);
    margin-bottom: 2px;
    /*margin-right: 20px;*/
    /*margin-left: 20px;*/
    padding: 0.5em;
    position: absolute;
    /*right: 0;*/
    top: 10em;
    /*width: calc(100% - 3em);*/
    width: 96%;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    background-color: rgba(255, 255, 255, 0.7);
}

.logoHeader {
    width: 6.5em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5em;
    
}


.fade-in {
    opacity: 0;
    animation: fade-in 1s ease-out forwards;
}

.headerSX {
    display: flex;
    align-items: center;
    font-size: 1.75em;
    margin-left: .45em;
}

.headerCX {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.headerDX {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

#Griglia .headerDX div,
img {
    cursor: pointer;
}

.piano #Griglia {
    cursor: pointer;
}

.piano #GrigliaAlarm {
    cursor: pointer;
}

.mini-selettore {
    font-size: 90%;
    font-weight: 400 !important;
    padding: 0 .75em !important;
    /*height: 1.25em;*/
    max-width: 6em;
}

.popupcenter {
    width: 60%;
}

.mini-selettore img {
    font-weight: 800;
}

.nowidth {
    width: auto !important;
}

.nowidth img {
    width: .9em;
}

.selettore {
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    line-height: 0;
    max-height: 2.5em;
    padding: 0 1.25em;
    margin: .15em .25em;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.selettorePurple {
    /*background: #4DA6DD !important;*/
    background: rgba(0, 163, 223);
    color: #fff;
}

.selettoreBlue {
    background: #4DA6DD !important;
    color: #fff;
}

.selettoreViolet {
    background: #F2F5FF !important;
    color: #4DA6DD;
    font-weight: 600;
}

.selettoreFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    float: right;
    /*width: 10em;*/
}

.selettoreMiddle {
    justify-content: center !important;
    width: 7.5em;
    cursor: initial;
}

.selettoreBorder {
    border: 1px solid #CDDEFF;
    background-color: #fff !important;
    color: #4DA6DD;
    font-weight: 600;
}

.selettoreShadow {
    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
}

.selettoreRound {
    border-radius: 50%;
    border: 1px solid #CDDEFF;
    background: #fff;
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin: .15em;
    cursor: pointer;
}

.selettoreHeader {
    line-height: 0 !important;
    font-size: 90%;
}

.disabled {
    opacity: .25;
    cursor: not-allowed;
}

.disabledLight {
    opacity: .7;
    cursor: not-allowed;
}

.visualizzazione {
    background-color: #4DA6DD;
    border: 0 solid;
    box-shadow: 0px 3px 10px rgba(90, 129, 250, 0.8);
}

.sottoSelettore {
    width: calc(90%);
    height: auto;
    max-height: 15em;
    overflow: auto;
    position: absolute;
    top: 2.95em;
    left: 0;
    z-index: 9 !important;
    padding: .5em 1em;
    background: #FFFFFF;
    border: 1px solid #CDDEFF;
    box-shadow: 0px 8px 10px rgba(43, 49, 138, 0.2);
    border-radius: 15px;
    transition: .2s;
    opacity: 0;
    display: none;
}

.flag {
    width: 2.5em;
    filter: grayscale(.2)
}

.sottoSelettore>.row {
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.5em;
    color: #A8B1CE;
    transition: .2s;
}

.sottoSelettore>.row img {
    transition: .2s;
}

.sottoSelettore>.row:hover {
    color: #4DA6DD;
    font-weight: 600;
}

.sottoSelettore>.row:hover img {
    padding-left: .5em;
}

.noCursor {
    cursor: inherit !important;
}

.bell {
    border-radius: 20px;
    padding: .25em;
    transition: .4s;
    margin-right: 4px;
}

.bell:hover {
    background: rgba(250, 0, 0, 0.1)
}

#ContentPage {
    align-items: center;
    border-radius: 0 0 20px 20px;
    color: #000;
    display: flexbox;
    overflow: hidden;
    height: calc(100% - 1.8em);
    justify-content: flex-start;
    overflow: auto;
    position: relative;
    top: 1em;
    width: 100%;
}
#ContentPageLow {
    margin-left: 15px;
    align-items: center;
    border-radius: 0 0 20px 20px;
    color: #000;
    display: flexbox;
    /*height: calc(100% - 1.8em);*/
    justify-content: flex-start;
    overflow: auto;
    position: relative;
    top: 3em;
    width: 100%;
    height: 90%;
    
}

#ContentPageHead {
    width: 100%;
    height: auto;
    margin-top: 0px;
    display: flexbox;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 0 0 20px 20px;
    color: #000;
}

#Griglia,
#GrigliaAlarm {
    /* width: 12.25em;
    height: 12em; RIATTIVARE PER TEMPERATURA STANZA*/
    background-color: white;
    padding: .5em;
    height: 180px;
    border-radius: 10px 25px 10px 25px;
    float: left;
    margin: .25em;
    margin-top: 2%;
    margin-bottom: 2%;
    line-height: 12px;
    font-size: 90%;
    position: relative;
    border: 1px solid #CDDEFF;
}

@keyframes blink {
    0% {
      opacity: 100%;
    }
    50% {
      opacity: 40%;
    }
    100% {
      opacity: 100%;
    }
  }
  
  .lamp-effect img {
    animation: blink 1s infinite;
  }
  
  
  



#Griglia {
    box-shadow: 0px 5px 8px rgba(43, 49, 138, 0.3);
    border-radius: 10px 25px 10px 25px;
}

#GrigliaAlarm {
    box-shadow: 0px 8px 15px rgba(43, 49, 138, 0.6);
    /* border: 1px solid rgba(255, 82, 73, 0.4); */
    border: 4px solid red;
}

@-webkit-keyframes borderBlinkResidentPresent {    
    from, to {border-color: red}
    50% {border-color: #168AF5}
}    
@keyframes borderBlinkResidentPresent {    
    from, to {border-color: red}
    30% {border-color: #168AF5}
}    
.borderBlinkResidentPresent{    
    border: 4px solid #168AF5;
}
.ResidentPresentAlarm {
    width: 20px;
    border-radius: 5px 20px 5px 20px;
    padding: 15px;
    background-color: #FFFFFF;
    color: black;
    font-weight: 600; 
    font-size: 1.15em; 
    justify-content: center;
    display: flex;
    height: 10px;
    align-items: center;
    border: 4px solid #168AF5;
    -webkit-animation: borderBlinkResidentPresent 3s step-end infinite;    
    animation: borderBlinkResidentPresent 3s step-end infinite;
    cursor: pointer;
}
.ResidentPresent {
    width: 20px;
    border-radius: 5px 20px 5px 20px;
    padding: 15px;
    background-color: #FFFFFF;
    color: black;
    font-weight: 600; 
    font-size: 1.15em; 
    justify-content: center;
    display: flex;
    height: 10px;
    align-items: center;
    border: 4px solid #168AF5;
    cursor: default;
}

.ResidentAlarm {
    width: 20px;
    border-radius: 5px 20px 5px 20px;
    padding: 15px;
    background-color: #FFFFFF;
    color: black;
    font-weight: 600; 
    font-size: 1.15em; 
    justify-content: center;
    display: flex;
    height: 10px;
    align-items: center;
    border: 4px solid red;
    cursor: pointer;
}
.Resident {
    width: 20px;
    border-radius: 5px 20px 5px 20px;
    padding: 15px;
    background-color: #FFFFFF;
    color: black;
    font-weight: 600; 
    font-size: 1.15em; 
    justify-content: center;
    display: flex;
    height: 10px;
    align-items: center;
    border: 4px solid #C9CACEFF;
    cursor: default;
}

.BathroomAlarm, .Bathroom, .BathroomPresentAlarm, .BathroomPresent {
    width: 20px;
    border-radius: 5px 20px 5px 20px;
    padding: 15px;
    background-color: #FFFFFF;
    color: "black";
    font-weight: 600;
    font-size: 1.15em;
    justify-content: center;
    display: flex;
    height: 10px;
    align-items: center;
}
.Bathroom {
    cursor: default;
    border: 4px solid #C9CACEFF;
}
.BathroomAlarm {
    cursor: pointer;
    border: 4px solid red;
}
.BathroomPresent {
    cursor: default;
    border: 4px solid #168AF5;
}
@-webkit-keyframes borderBlinkBathroomPresent {    
    from, to {border-color: red}
    50% {border-color: #168AF5}
}    
@keyframes borderBlinkBathroomPresent {    
    from, to {border-color: red}
    30% {border-color: #168AF5}
}    
.borderBlinkBathroomPresent{    
    border: 4px solid #168AF5;
}
.BathroomPresentAlarm {
    cursor: pointer;
    border: 4px solid #168AF5;
    -webkit-animation: borderBlinkBathroomPresent 3s step-end infinite;    
    animation: borderBlinkBathroomPresent 3s step-end infinite;
}



.ResidentBedBusy, .ResidentBedEmpty, .ResidentBedBusyAlarm, .ResidentBedEmptyAlarm, .ResidentBedFall, .ResidentBedBusyAlarmAndFall, 
.ResidentBedEmptyAlarmAndFall, .ResidentBedOffline, .ResidentBedAlarmAndOffline, .ResidentBedFallAndOffline, .ResidentBedAlarmAndFallAndOffline,
.ResidentBedBusy_Presence, .ResidentBedEmpty_Presence, .ResidentBedOffline_Presence {
    width: 60px;
    height: 60px;
    cursor: default;
}
.ResidentBedBusy {
    content: url('../imgs/icons/Busybed.svg');
}
.ResidentBedEmpty {
    content: url('../imgs/icons/emmptyroom.svg');
}
.ResidentBedBusy_Presence {
    cursor: pointer;
    content: url('../imgs/icons/Busybed.svg');
}
.ResidentBedEmpty_Presence {
    cursor: pointer;
    content: url('../imgs/icons/emmptyroom.svg');
}
.ResidentBedBusyAlarm {
    cursor: pointer;
    content: url('../imgs/icons/Busybed_alarm.svg');
}
.ResidentBedEmptyAlarm {
    cursor: pointer;
    content: url('../imgs/icons/emmptyroom_alarm.svg');
}
.ResidentBedFall {
    cursor: pointer;
    content: url('../imgs/icons/falldetected.svg');
}
@-webkit-keyframes blink_ResidentBedBusyAlarmAndFall {    
    from, to {content: url('../imgs/icons/falldetected.svg');}    
    50% {content: url('../imgs/icons/Busybed_alarm.svg');}    
}    
@keyframes blink_ResidentBedBusyAlarmAndFall {    
    from, to {content: url('../imgs/icons/falldetected.svg');}    
    50% {content: url('../imgs/icons/Busybed_alarm.svg');}
}    
.blink_ResidentBedBusyAlarmAndFall{    
    content: url('../imgs/icons/Busybed_alarm.svg');
}
.ResidentBedBusyAlarmAndFall {
    cursor: pointer;
    content: url('../imgs/icons/Busybed_alarm.svg');
    -webkit-animation: blink_ResidentBedBusyAlarmAndFall 3s step-end infinite;    
    animation: blink_ResidentBedBusyAlarmAndFall 3s step-end infinite;
}
@-webkit-keyframes blink_ResidentBedEmptyAlarmAndFall {    
    from, to {content: url('../imgs/icons/falldetected.svg');}
    50% {content: url('../imgs/icons/emmptyroom_alarm.svg');}
}    
@keyframes blink_ResidentBedEmptyAlarmAndFall {    
    from, to {content: url('../imgs/icons/falldetected.svg');}
    50% {content: url('../imgs/icons/emmptyroom_alarm.svg');}
}
.blink_ResidentBedEmptyAlarmAndFall{    
    content: url('../imgs/icons/emmptyroom_alarm.svg');
}
.ResidentBedEmptyAlarmAndFall {
    cursor: pointer;
    content: url('../imgs/icons/emmptyroom_alarm.svg');
    -webkit-animation: blink_ResidentBedEmptyAlarmAndFall 3s step-end infinite;    
    animation: blink_ResidentBedEmptyAlarmAndFall 3s step-end infinite;
}
.ResidentBedOffline {
    content: url('../imgs/icons/offline-icon-resident.svg');
}
.ResidentBedOffline_Presence {
    cursor: pointer;
    content: url('../imgs/icons/offline-icon-resident.svg');
}
@-webkit-keyframes blink_ResidentBedAlarmAndOffline {    
    from, to {content: url('../imgs/icons/offline-icon-resident.svg');}
    50% {content: url('../imgs/icons/emmptyroom_alarm.svg');}
}    
@keyframes blink_ResidentBedAlarmAndOffline {    
    from, to {content: url('../imgs/icons/offline-icon-resident.svg');}
    50% {content: url('../imgs/icons/emmptyroom_alarm.svg');}
}
.blink_ResidentBedAlarmAndOffline {
    content: url('../imgs/icons/emmptyroom_alarm.svg');
}
.ResidentBedAlarmAndOffline{
    cursor: pointer;
    content: url('../imgs/icons/emmptyroom_alarm.svg');
    -webkit-animation: blink_ResidentBedAlarmAndOffline 3s step-end infinite;    
    animation: blink_ResidentBedAlarmAndOffline 3s step-end infinite;
}
@-webkit-keyframes blink_ResidentBedFallAndOffline {    
    from, to {content: url('../imgs/icons/offline-icon-resident.svg');}
    50% {content: url('../imgs/icons/emmptyroom_alarm.svg');}
}    
@keyframes blink_ResidentBedFallAndOffline {    
    from, to {content: url('../imgs/icons/offline-icon-resident.svg');}
    50% {content: url('../imgs/icons/falldetected.svg');}
}
.blink_ResidentBedFallAndOffline {
    content: url('../imgs/icons/falldetected.svg');
}
.ResidentBedFallAndOffline{
    cursor: pointer;
    content: url('../imgs/icons/falldetected.svg');
    -webkit-animation: blink_ResidentBedFallAndOffline 3s step-end infinite;    
    animation: blink_ResidentBedFallAndOffline 3s step-end infinite;
}
@-webkit-keyframes blink_ResidentBedAlarmAndFallAndOffline {    
    0% {content: url('../imgs/icons/offline-icon-resident.svg')}
	33%{content: url('../imgs/icons/falldetected.svg')}
	66% {content: url('../imgs/icons/emmptyroom_alarm.svg')}
}    
@keyframes blink_ResidentBedAlarmAndFallAndOffline {  
    0% {content: url('../imgs/icons/offline-icon-resident.svg')}
	33%{content: url('../imgs/icons/falldetected.svg')}
	66% {content: url('../imgs/icons/emmptyroom_alarm.svg')}  
}
.blink_ResidentBedAlarmAndFallAndOffline {
    content: url('../imgs/icons/emmptyroom_alarm.svg');
}
.ResidentBedAlarmAndFallAndOffline {
    cursor: pointer;
    content: url('../imgs/icons/emmptyroom_alarm.svg');
    -webkit-animation: blink_ResidentBedAlarmAndFallAndOffline 4.5s step-end infinite;    
    animation: blink_ResidentBedAlarmAndFallAndOffline 4.5s step-end infinite;
}


.BathroomBusy, .BathroomEmpty, .BathroomOffline, .BathroomBusy_Presence, .BathroomEmpty_Presence, .BathroomOffline_Presence {
    width: 60px;
    height: 60px;
    cursor: default;
}
.BathroomBusy {
    content: url('../imgs/icons/busybathroom-resident.svg');
}
.BathroomEmpty {
    content: url('../imgs/icons/emptybathroom-resident.svg');  
}
.BathroomOffline {
    content: url('../imgs/icons/offline-icon-resident.svg');
}
.BathroomBusy_Presence {
    cursor: pointer;
    content: url('../imgs/icons/busybathroom-resident.svg');
}
.BathroomEmpty_Presence {
    cursor: pointer;
    content: url('../imgs/icons/emptybathroom-resident.svg');  
}
.BathroomOffline_Presence {
    cursor: pointer;
    content: url('../imgs/icons/offline-icon-resident.svg');
}
.BathroomFall {
    cursor: pointer;
    content: url('../imgs/icons/falldetected.svg');
}
@-webkit-keyframes blink_BathroomFallAndOffline {    
    from, to {content: url('../imgs/icons/falldetected.svg');}    
    50% {content: url('../imgs/icons/offline-icon-resident.svg');}
}    
@keyframes blink_BathroomFallAndOffline {    
    from, to {content: url('../imgs/icons/falldetected.svg');}
    50% {content: url('../imgs/icons/offline-icon-resident.svg');}
}    
.blink_BathroomFallAndOffline{    
    content: url('../imgs/icons/offline-icon-resident.svg');
}
.BathroomFallAndOffline{
    cursor: pointer;
    content: url('../imgs/icons/offline-icon-resident.svg');
    -webkit-animation: blink_BathroomFallAndOffline 3s step-end infinite;    
    animation: blink_BathroomFallAndOffline 3s step-end infinite;
}



.AmbienteBagnoBusy, .AmbienteBagnoEmpty, .AmbienteVarcoBusy, .AmbienteVarcoEmpty, .AmbienteCucinaBusy, .AmbienteCucinaEmpty, 
.AmbienteSoggiornoBusy, .AmbienteSoggiornoEmpty, .AmbienteAscensoreBusy, .AmbienteAscensoreEmpty, .AmbienteFall, .AmbienteOffline,
.AmbienteFallAndOffline {
    width: 80px;
    height: 80px;
    cursor: default;
}
.AmbienteBagnoBusy {
    content: url('../imgs/icons/busybathroom.svg');
}
.AmbienteBagnoEmpty {
    content: url('../imgs/icons/emptybathroom.svg');
}
.AmbienteVarcoBusy {
    content: url('../imgs/icons/busygap.svg');
}
.AmbienteVarcoEmpty {
    content: url('../imgs/icons/emptygap.svg');
}
.AmbienteCucinaBusy {
    content: url('../imgs/icons/Component 7.svg');
}
.AmbienteCucinaEmpty {
    content: url('../imgs/icons/Component 6.svg');
}
.AmbienteSoggiornoBusy {
    content: url('../imgs/icons/busylivingroom.svg');
}
.AmbienteSoggiornoEmpty {
    content: url('../imgs/icons/freelivingroom.svg');
}
.AmbienteAscensoreBusy {
    content: url('../imgs/icons/busyescalator.svg');
}
.AmbienteAscensoreEmpty {
    content: url('../imgs/icons/emptyescalator.svg');
}
.AmbienteFall {
    cursor: pointer;
    content: url('../imgs/icons/falldetected.svg');
}
.AmbienteOffline {
    content: url('../imgs/icons/offline-icon.svg');
}
@-webkit-keyframes blink_AmbienteFallAndOffline {    
    from, to {content: url('../imgs/icons/falldetected.svg');}    
    50% {content: url('../imgs/icons/offline-icon.svg');}
}    
@keyframes blink_AmbienteFallAndOffline {    
    from, to {content: url('../imgs/icons/falldetected.svg');}
    50% {content: url('../imgs/icons/offline-icon.svg');}
}    
.blink_AmbienteFallAndOffline{    
    content: url('../imgs/icons/offline-icon.svg');
}
.AmbienteFallAndOffline {
    cursor: pointer;
    content: url('../imgs/icons/offline-icon.svg');
    -webkit-animation: blink_AmbienteFallAndOffline 3s step-end infinite;    
    animation: blink_AmbienteFallAndOffline 3s step-end infinite;
}



#Griglia table,
#GrigliaAlarm table {
    width: 200px;
    border-collapse: collapse;
}

#Griglia td,
#GrigliaAlarm td {
    padding: 0;
   /* line-height: 20px;*/
   line-height: 1.6rem;
}

#ListaResidenti {
    width: 100%;
    height: 65%;
    overflow: auto;
}

#ListaAmbienti {
    width: 100%;
    height: 35%;
    overflow: auto;
}

#Lista,
#TitoliLista,
.scheda thead {
    width: calc(100% - .25em);
    font-weight: 300 !important;
    font-size: 18px;
    padding-left: .25em;
    line-height: 15px;
    font-size: 95%;
}

#TitoliLista {
    color: #4DA6DD;
    position: sticky;
    /*background: #fff;*/
    top: 0;
    z-index: 1;
    font-size: 90%;
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #CDDEFF;
    border-radius: 10px;
}

#TitoliLista td {
    vertical-align: middle;
    line-height: 100%;
    padding: .15em;
}

#Lista {
    height: 2.65em;
    border-bottom: 1px solid #F5F5F5;
    transition: .4s;
}

#Lista:hover {
    background: rgba(0, 0, 0, 0.02);
}

#Lista td,
#TitoliLista td {
    text-align: left;
    padding-left: .25em;
    font-weight: 300;
    position: relative;
    z-index: 0 !important;
}

.checkbox-column {
    text-align: left;
    margin-left: 0;
    padding: 0;
    position: relative;
    z-index: 1 !important;
    
}

.allarme {
    /*background: #FBE6E5 !important;*/

    /*color: #FBE6E5;*/
    border-radius: 20px;
    margin: .15em 0;
    font-weight: bold !important;
}

/*.allarme td  {*/
/*    background: #FBE6E5 !important;*/
/*}*/

.allarme td:first-child,
.allarme th:first-child {
    border-radius: 20px 0 0 20px;
    /*background: #FBE6E5;*/
}
.allarme td:last-child,
.allarme th:last-child {
    border-radius: 0 20px 20px 0;
    /*background: #FBE6E5 !important;*/
}

.allarme {
    /*background: #FBE6E5 !important;*/
    /*color: #FBE6E5;*/
    border-radius: 20px;
    margin: .15em 0;
    font-weight: bold !important;
}

/*background: #FBE6E5 !important;*/

/*.tablestraip tr:nth-child(even) {background-color: red;}*/
/*.tablestraip tr:nth-child(odd) {background-color: #811331;}*/

.tablestraip .alerta:nth-child(even) {background-color: #fbd9d3;}

.tablestraip .alerta:nth-child(odd) {background-color: #fbd9d3;}

.tablestraip tr:nth-child(even) {
    background-color: #f5f5f5;
    /*border-radius: 20px;*/
    /*margin: .15em 0;*/
    /*font-weight: bold !important;*/
}
.tablestraip tr:nth-child(odd) {
    background-color: #f5f5f5;
    /*border-radius: 20px;*/
    /*margin: .15em 0;*/
    /*font-weight: bold !important;*/
}


/*.tablestraip tr td {*/
/*    border: 1px solid lightgray;*/
/*}*/

.tablestraip tr td:first-child {
 /*background-color: green;*/
    border-top: 1px solid lightgray !important;
    border-bottom: 1px solid lightgray !important;
    border-left: 1px solid lightgray !important;
}

.tablestraip tr td:last-child {
    border-top: 1px solid lightgray !important;
    border-bottom: 1px solid lightgray !important;
    border-right: 1px solid lightgray !important;
}
.tablestraip tr td:nth-child(2) {
    border-top: 1px solid lightgray !important;
    border-bottom: 1px solid lightgray !important;
}
.tablestraip tr td:nth-child(3) {
    border-top: 1px solid lightgray !important;
    border-bottom: 1px solid lightgray !important;
}
.tablestraip tr td:nth-child(4) {
    border-top: 1px solid lightgray !important;
    border-bottom: 1px solid lightgray !important;
}





.tablestraip td:first-child,
.tablestraip th:first-child {
    border-radius: 20px 0 0 20px;
    /*background: #FBE6E5 !important;*/
}
.tablestraip td:last-child,
.tablestraip th:last-child {
    border-radius: 0 20px 20px 0;
    /*background: #FBE6E5 !important;*/
}

.allarme {
    /*background: #FBE6E5 !important;*/
    /*color: #FBE6E5;*/
    border-radius: 20px;
    margin: .15em 0;
    font-weight: bold !important;
}

.table-notifiche tr:nth-child(odd) {
 background-color: #fcfcfc;
}

/*.tetstt :nth-child(even) {*/
/*    !*background: #00A3DF;*!*/
/*    color: black;*/
/*    background-color: #04AA6D;*/
/*}*/

.striped-table tr:nth-child(even) {
    background-color: #f8e0da;
}
.striped-table tr:nth-child(odd) {
    background-color: #f8e0da;
}

/*.striped-table-1 tr:nth-child(odd) {*/
/*    background-color: lightgray;*/
/*}*/



.tableFixHead {
    /*width: 500px;*/
    table-layout: fixed;
    border-collapse: collapse;
    height: 100%;
    width: 100%;
}
.tableFixHead tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 100px;
    /*height: calc(100% - 80px);*/
}
.tableFixHead thead tr {
    display: block;
}
.tableFixHead th,
.tableFixHead  td {
    padding: 5px 10px;
    /*width: 200px;*/
    width: 3%;
}

td:first-child,
th:first-child {
    border-radius: 10px 0 0 10px;
}
td:last-child,
th:last-child {
    border-radius: 0 10px 10px 0;
}

tr:first-child,
th:first-child {
    border-radius: 10px 0 0 10px;
}
tr:last-child,
th:last-child {
    border-radius: 0 10px 10px 0;
}

/*.tablestraip tr:nth-child(even) {*/
/*    background-color: #f2f2f2;*/
/*}*/





.arrowTitoli {
    position: relative;
    top: .1em;
}

.selected {
    font-weight: 600 !important;
}

.left {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em !important;
}

.right {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.alarm,
.alarmG {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 85%;
    font-weight: 500;
}


.alarm img {
    margin-right: .05em;
    margin-top: .15em;
    height: 90%;
}

.alarmG img {
    margin-right: -.25em;
    margin-top: .25em;
    height: 140%;
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    right: 1.5em;
    margin-left: .5em;
}

.topLeft {
    position: absolute !important;
    top: 0;
    left: 0;
}

.medium {
    width: 7.5px !important;
    height: 7.5px !important;
}

.big {
    width: 10px !important;
    height: 10px !important;
}

.red {
    background: #FF5149;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #FF5149;
}

.green {
    background: #18FFA9 !important;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #18FFA9 !important;
}

.orange {
    background: orange !important;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px orange !important;
}

.yellow {
    background: #FFE500;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 4px #FFE500;
}

.blue {
    background: #5A81FA;
    box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.4), 1px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 5px #5A81FA;
}

.bgRed {
    background: #FBE6E5;
}

.bgYellow {
    background: #FEF9E2;
}

.normPosition {
    position: relative;
    /*right: 0;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: 0;*/
    float: left;
    /*margin: .5em;*/
    /*transform: translate(0, -40%);*/
}

#Popup,
#PopupHeader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(43, 49, 138, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

#PopupBody {
    position: relative;
    width: calc(100% - 1em);
    height: auto;
    padding: .5em;
}

.popupCardContainer {
    width: 100% !important;
    height: 100%;
    min-height: 30em;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    position: relative;
}

.popupCardContainer::-webkit-scrollbar {
    display: none;
}

.popupCard {
    width: auto;
    height: 100%;
    min-height: 20em;
    margin: 0 .5em 1em .5em;
    border-radius: 22.5px;
}

.popupCard td>label {
    position: relative;
    top: 1em;
}

.popupNavigator {
    width: 100%;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    margin: 0;
    bottom: 0;
}

.popupButtons {
    position: absolute;
    bottom: .5em;
    right: 1em;
}

.popupLegend {
    position: absolute;
    bottom: .5em;
    left: 1em;
}

.popupNavigator img {
    filter: opacity(0.5);
}

.popupNavigator img:hover {
    filter: opacity(1);
}

.popupNavigatorArrow {
    margin: 0 0 0 1em;
    opacity: .5;
}

.popupNavigatorArrow:hover {
    opacity: 1;
}


#MiniPopup {
    position: absolute;
    top: 5em;
    z-index: 100;
    background: #F2F5FF;
    box-shadow: rgba(90, 129, 250, 0.3) 0px 10px 10px;
    border-radius: 22.5px;
    padding: .5em;
    width: 15em;
    display: flex;
    flex-direction: column;
}

#MiniPopup input {
    width: 87.5%;
    position: relative;
    top: .5em;
    left: 0;
    margin: .75em 0 0 0;
    transform: translate(0)
}

#MiniPopup label {
    color: #4DA6DD !important;
    font-weight: 200;
    font-size: 90%;
    position: relative;
    left: .5em;
}

.minipopTable td {
    padding: 0 !important;
}

.minipopTable .selettore {
    width: 80%;
    margin-top: .5em;
}

.minipopTable label {
    position: relative;
    top: 1em;
}

.delMiniPopup {
    position: absolute;
    top: .5em;
    right: 1em;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    line-height: 0;
}

.delMiniPopup:hover {
    background-color: rgba(250, 0, 0, 0.1)
}


.popupContent {
    max-width: calc(100% - 6em);
    max-height: calc(100% - 2.5em);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 40px #4DA6DD;
    /*margin-left: 5em;*/
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 20%;
}

.closePopup {
    position: absolute;
    top: 1em;
    right: 1.5em;
    padding: .5em;
    border-radius: 100%;
}

.closePopup:hover {
    background-color: rgba(200, 200, 200, 0.2);
}

#options {
    width: 8em;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    padding: .25em;
    margin: 0 .5em;
    z-index: 1;
    position: absolute;
    top: -1.3em;
    left: 50%;
    transform: translate(-50%, 0);
    transition: .2s ease-in-out;
}

#options:hover {
    background: #F2F5FF;
    top: 0;
}

#handle {
    width: 100%;
    height: 4em;
    border-radius: 20px 20px 0 0;
    background-color: #F2F5FF;
    position: relative;
    top: 0;
    left: 0;
    cursor: grab;
    display: flex;
    align-items: center;
}

#handle:active {
    cursor: grabbing;
}

#PopupFooter {
    width: 100%;
    height: 2.5em;
    margin-top: 2em;
    position: relative;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#ContentStruttura {
    width: 100%;
    height: calc(100% - 2em);
    margin: 0;
    margin-top: 4px;
    padding: 0;
    position: relative;
}

#Strutture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
}

#Magazzino {
    width: auto;
    height: calc(100% - .25em);
    margin-top: 0.25em;
    padding: 0;
    color: #fff;
    z-index: 2;
    position: absolute;
    top: 0;
    right: .15em;
}

.headerMagazzino {
    background: #5A81FA;
    border-radius: 20px;
    width: 1.75em;
    height: calc(100% - 2em);
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
}

.headerMagazzino img {
    display: none;
}

.headerMagazzino p {
    transform: rotate(-90deg);
    margin-bottom: 3em;
    transition: .2s;
}

.contentMagazzino {
    width: 0;
    height: 100%;
    float: right;
    margin-left: .35em;
    overflow: hidden;
    background: rgba(90, 129, 250, 0.7);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    transition: .2s;
    position: relative;
    right: .25em;
    overflow: auto;
}

.contentMagazzino::-webkit-scrollbar-track {
    margin: 1em;
}

.tabMagazzino {
    width: calc(100%- 2em);
    padding: .5em;
    margin: .5em;
    background-color: rgb(242, 245, 255);
    border-radius: 15px;
    color: #4DA6DD;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tabMagazzino:hover {
    background-color: rgba(242, 245, 255, 0.9);
}

.tabMagazzino p {
    margin: 0 .5em;

}

.tabStruttura {
    height: auto;
    max-height: 2.6em;
    overflow: hidden;
    float: left;
    transition: .4s;
    position: relative;
    padding: 0;
    margin-top: .25em;
    color: #4DA6DD;
}

.piano {
    width: calc(100% - 2.25em);
}

.ala,
.corridoio {
    float: right;
    width: calc(100% - 2em);
}

.stanza {
    float: right;
    width: calc(100% - 2em) !important;
    display: flex;
    align-items: center;
    flex-direction: flex-start;
    margin: .15em 0;
}

.add {
    cursor: pointer;
    border: 0 !important;
    font-weight: 800;
    display: none;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1em;
    padding-right: .25em;
    position: relative;
}

.add img {
    margin: 0 1em;
}

.add2 {
    border: 0 !important;
    font-weight: 800;
    height: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1.1em;
    padding-right: .25em;
    position: relative;
    width: auto;
    min-width: 30em;
}

.add2 img {
    width: 1em;
}

.add2 div {
    font-weight: 200;
}

.headerStruttura {
    width: calc(100% - .15em);
    height: 2.6em;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
}

.mini-tab {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 4px solid #fff;
    padding-left: 1em;
    padding-right: .25em;
    position: relative;
}

.mini-tab div {
    display: flex;
    align-items: center;
}

.modifica {
    background: rgba(43, 49, 138, 0.4);
    backdrop-filter: blur(4px);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2 !important;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 2.5em);
    height: 100%;
    align-items: center;
}

.hov {
    opacity: 0;
}

.hov:hover {
    opacity: 1;
}

.full {
    width: 100% !important;
}

.inputMenuMod {
    width: 12.5em;
    max-width: 12.5em;
    transform: translate(0, 0);
    background: transparent;
    color: #fff;
    border: 0px;
    font-size: 100%;
    font-family: 'Montserrat', sans-serif;
    margin-left: -.4em;
}

.inputMenuMod::placeholder {
    color: #fff;
    opacity: 1;
}

.inputMenuMod:focus {
    background: transparent;
    color: #fff;
    border: 0px;
}

.inputnumber {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

.mini-tab-stanza {
    border-right: 1px solid #F5F5F5;
    font-weight: 500;
}

.mini-piano {
    min-width: 22em !important;
    border-radius: 20px 0 0 20px;
}

.mini-ala {
    min-width: 20em !important;
    border-radius: 20px 0 0 20px;
}

.mini-corridoio {
    min-width: 18em !important;
    border-radius: 20px 0 0 20px;
}

.mini-right {
    height: 1.25em;
    width: 1.25em;
    float: right;
    position: relative;
}

.mini-right img {
    width: 1.25em;
}

.scheda {
    height: auto;
    min-height: 250px;
    position: relative;
    transition: .4s;
    width: 100%;
    margin: 1em 0;
}

.scheda td {
    padding: 1.5em;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
}

.schedaNoBorder td {
    border: 0px solid transparent;
    padding: 0;
}

.tdPointer td {
    cursor: pointer;
    font-weight: 200;
}

.tdPointer td:hover {
    font-weight: 600;
}

.scheda label {
    position: relative;
    color: #000 !important;
    font-size: 85%;
    font-weight: 200;
}

.noBorder {
    border: 0px solid transparent;
    margin-top: .5em;
}

.noBorder td {
    border: 0px solid transparent;
}

.schedaSotto {
    height: auto;
    min-height: 50px;
    transition: .4s;
    width: 100%;
}

.schedaImg img {
    width: 100%;
    height: 100%;
}

.navigators {
    width: 100%;
    height: 2em;
}

#noData {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#noData p {
    font-size: 1.5em;
    font-weight: 500;
    color: #4DA6DD;
    animation: blinker 2.5s linear infinite;
}

.progress-bar-container {
    background-color: rgb(242, 245, 255);
    border-radius: 10px;
    height: 1em;
    width: 100%;
    margin: 2em 0 0 0;
    position: relative;
    overflow: hidden;
}

.progress-bar {
    background-color: #5A81FA;
    border-radius: 10px;
    height: 100%;
    transition: width 1s linear;
    float: left;
}

.progress-bar-n {
    color: #fff;
    position: absolute;
    top: 0;
    right: .5em;
    float: left;
    transform: translate(0, -80%);
    filter: drop-shadow(0px 0px 1px #000);
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

#loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(43, 49, 138, 0.1);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../imgs/loading.png");
    background-repeat: no-repeat;
    background-position: center;
    display: none;
}

::-webkit-scrollbar {
    width: 5px;
}

/*::-webkit-scrollbar-thumb {*/
/*    background-color: blue !important;    !* color of the scroll thumb *!*/
/*    border-radius: 20px !important;       !* roundness of the scroll thumb *!*/
/*    border: 3px solid orange !important;  !* creates padding around scroll thumb *!*/
/*}*/



/*::-webkit-scrollbar-track*/
/*{*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
/*    background-color: #F5F5F5;*/
/*}*/

/*::-webkit-scrollbar*/
/*{*/
/*    width: 10px;*/
/*    background-color: #F5F5F5;*/
/*}*/

/*::-webkit-scrollbar-thumb*/
/*{*/
/*    background-color: #F90;*/
/*    background-image: -webkit-linear-gradient(45deg,*/
/*    rgba(255, 255, 255, .2) 25%,*/
/*    transparent 25%,*/
/*    transparent 50%,*/
/*    rgba(255, 255, 255, .2) 50%,*/
/*    rgba(255, 255, 255, .2) 75%,*/
/*    transparent 75%,*/
/*    transparent)*/
/*}*/

.scrol-item::-webkit-scrollbar {
    width: 10px;
}


.scrol-item::-webkit-scrollbar-track {

    border-radius: 10px;
}


.scrol-item::-webkit-scrollbar-thumb {
    background: #5A81FA;
    border-radius: 10px;
}


.scrol-item::-webkit-scrollbar-thumb:hover {
    background: #5A81FA;
}





::-webkit-scrollbar-thumb {
    background-color: #5A81FA;
    border-radius: 5px;
    border: 3px solid transparent;
    background-clip: content-box;
    scrollbar-width: thin;
}

::-webkit-scrollbar-track {
    margin-top: 3px;
    margin-bottom: 10px;
}

.main-header {
    background: rgba(0,163,223)
}

.header-center-select {
    position: absolute;
    /*left: 45%;*/
    width: auto!important;
    min-width: 10em;
}

.table-scrol {
    height: calc(100% - 4.5em);
    overflow:auto;
    margin: auto;
    position: relative;
}

.table-scrol table {
    width: 100%;
    min-width: 900px;
    margin: auto;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    word-wrap: break-word;
}

/*table {*/
/*    border-radius: var(--cui-border-radius) !important;*/
/*    overflow: hidden !important;*/
/*}*/


.btn-group button {
    background-color: #04AA6D; /* Green background */
    border: 1px solid green; /* Green border */
    color: white; /* White text */
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
    content: "";
    clear: both;
    display: table;
}

.btn-group button:not(:last-child) {
    border-right: none; /* Prevent double borders */
}

/* Add a background color on hover */
.btn-group button:hover {
    background-color: #3e8e41;
}


.backgraund-trasparent {
    background-color: transparent;
}

@media(max-width: 971px) {
    /*.main-header {*/
    /*    background: red;*/
    /*}*/
    .header-center-select {
        left: 0%;
    }
}

@media(max-width: 1425px) {
    .popupcenter {
        width: 70%;
    }
}

@media(max-width: 1200px) {
    .popupcenter {
        width: 80%;
    }
}

@media(max-width: 1085px) {
    .popupcenter {
        width: 90%;
    }
}


.width-5rem {
    width: 5rem;
}



.icon-popup {
    width: 0.8rem;
    margin-left: 4px;
}

/*td, th, tr, table {*/
/*    border: 1px !important;*/
/*    border-spacing: 0 !important;*/
/*}*/

/*table, tr {*/
/*    border: 1px solid lightgray;*/
/*    border-collapse: collapse;*/
/*}*/




table {
    border-collapse: collapse;
    text-align: center;
}

/*tr {*/
/*    border-bottom: 1px solid black !important;*/
/*}*/

.border-0 {
    border: 0 !important;
    border-spacing: 0 !important;
}

input[type=email], select, textarea,
input[type=password], select, textarea,
input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    /*border-radius: 4px;*/
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=submit] {
    background-color: #04AA6D;
    color: white;
    /*padding: 12px 20px;*/
    border: none;
    /*border-radius: 4px;*/
    cursor: pointer;
}

.intro {
    max-width: 1280px;
    margin: 1em auto;
}
.table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 85%;
}
.table-scroll table {
    width: 100%;
    min-width: 708px;
    margin: auto;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    word-wrap: break-word;
}
.table-wrap {
    position: relative;
}
.table-scroll th,
.table-scroll td {
    padding: 5px 10px;
    /*border: 1px solid #000;*/
    background: transparent;
    vertical-align: top;
    width: 150px;
    font-weight: 300;
}
.table-scroll thead th {
    background: #fff !important; /* I know */
    color: black;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.online {
    color: #18FFA9;
    font-size: x-large;
}
.offline {
    color: red;
}

.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #666 !important;
    color: #fff;
    z-index: 4;
}
#main-table thead tr th {
    color: #4DA6DD;
    font-weight: 300;
}


.table-scroll2 thead th {
    background: #00A3DF!important; /* I know */
    color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 10px;
}

.p-0 {
    padding: 0;
}

.width-height-auto {
    width: auto;
    max-height: none;
}
.form-zohoh {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
}

.formleble {
    position: relative;
    width: 100%
}


/*
#zohoSupportWebToCase textarea,
#zohoSupportWebToCase input[type="text"],
#zohoSupportWebToCase select,
.wb_common {
    width: 280px;
}
#zohoSupportWebToCase td {
    padding: 11px 5px;
}
#zohoSupportWebToCase textarea,
#zohoSupportWebToCase input[type="text"],
#zohoSupportWebToCase select {
    border: 1px solid #ddd;
    padding: 3px 5px;
    border-radius: 3px;
}
#zohoSupportWebToCase select {
    box-sizing: unset;
}
#zohoSupportWebToCase .wb_selectDate {
    width: auto;
}
#zohoSupportWebToCase input.wb_cusInput {
    width: 108px;
}
.wb_FtCon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    padding-left: 10px;
}
.wb_logoCon {
    display: flex;
    margin-left: 5px;
}
.wb_logo {
    max-width: 16px;
    max-height: 16px;
}
#zohoSupportWebToCase .wb_multi_pick {
    border: 1px solid #ddd;
    padding: 3px 5px;
    border-radius: 3px;
    width: 280px;
    height: 95px;
    overflow-y: auto;
}
#zohoSupportWebToCase .wb_multi_pick_label {
    display: block;
}
#zohoSupportWebToCase .wb_multi_pick_input,
.wb_multi_pick_input_all {
    vertical-align: middle;
    margin-right: 5px;
}
.zsFormClass {
    background-color: #def0ff;
    width: 600px;
}
.zsFontClass {
    color: #0394fc;
    font-family: Tahoma;
    font-size: 12px;
}

.manfieldbdr {
    border-left: 1px solid #ff6448 !important;
}
.hleft {
    text-align: left;
}
input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
}
.wtcsepcode {
    margin: 0px 15px;
    color: #aaa;
    float: left;
}
.wtccloudattach {
    float: left;
    color: #00a3fe !important;
    cursor: pointer;
    text-decoration: none !important;
}
.wtccloudattach:hover {
    text-decoration: none !important;
}
.wtcuploadinput {
    cursor: pointer;
    float: left;
    width: 62px;
    margin-top: -20px;
    opacity: 0;
    clear: both;
}
.wtcuploadfile {
    float: left;
    color: #00a3fe;
}
.filenamecls {
    margin-right: 15px;
    float: left;
    margin-top: 5px;
}
.clboth {
    clear: both;
}
#zsFileBrowseAttachments {
    clear: both;
    margin: 5px 0px 10px;
}
.zsFontClass {
    vertical-align: top;
}
#tooltip-zc {
    font: normal 12px Arial, Helvetica, sans-serif;
    line-height: 18px;
    position: absolute;
    padding: 8px;
    margin: 20px 0 0;
    background: #fff;
    border: 1px solid #528dd1;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    color: #eee;
    -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    color: #777;
}
.wtcmanfield {
    color: #f00;
    font-size: 16px;
    position: relative;
    top: 2px;
    left: 1px;
}
#zsCloudAttachmentIframe {
    width: 100%;
    height: 100%;
    z-index: 99999 !important;
    position: fixed;
    left: 0px;
    top: 0px;
    border-style: none;
    display: none;
    background-color: #fff;
}
.wtchelpinfo {
    background-position: -246px -485px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    top: 2px;
    background-image: url(https://img.zohostatic.eu/support/app/images/zs-mpro.b6c9cf2347c62390fdcb.png);
}
.zsMaxSizeMessage {
    font-size: 13px;
}
*/


